<template>
  <div style="max-height: 100vh" class="overflow-y-auto">
    <v-card elevation="0" flat>
      <v-app-bar flat dark color="primary">
        <v-toolbar-title v-if="operation === 'create'">Nouveau équipement</v-toolbar-title>
        <v-toolbar-title v-if="operation === 'update'">Mettre à jour l'équipement</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-app-bar>
      <v-card-text>
        <v-form ref="assetForm" class="px-3">
          <v-text-field label="Nom" :rules="[uniqueName, notNullNorEmpty, limitCharacters]" v-model="asset.name"></v-text-field>
          <v-text-field label="Balise" :rules="[uniqueBalise, notNullNorEmpty]" v-model="asset.balise"></v-text-field>
          <v-autocomplete label="Site"
                          item-text="name"
                          item-value="id"
                          :rules="[asset.site !== null || 'Vous devez spécifier un site pour l\'équipement.']"
                          :items="sites" v-model="asset.site">
          </v-autocomplete>
          <v-radio-group class="ma-0 pa-0" v-model="asset.site">
            <v-treeview
                :items="sites_tree"
                item-text="name"
                item-key="id"
                item-children="sites"
                dense>
              <template v-slot:append="{item}">
                <v-radio :value="item.id"></v-radio>
              </template>
            </v-treeview>
          </v-radio-group>
          <v-text-field label="Référence" v-model="asset.reference"></v-text-field>
          <v-text-field label="Marque" v-model="asset.marque"></v-text-field>
          <v-text-field label="Numéro de série" :rules="[uniqueSerialNumber]" v-model="asset.numero_serie"></v-text-field>
          <v-autocomplete label="Domaine"
                          item-text="name"
                          item-value="id"
                          :rules="[notEmptyList]"
                          :items="domaines" v-model="asset.category">
          </v-autocomplete>
          <v-autocomplete label="Intervenant"
                          item-text="fullname"
                          item-value="id"
                          :items="intervenants" v-model="asset.intervenant">
          </v-autocomplete>
          <v-autocomplete label="Impact"
                          :items="impact"
                          v-model="asset.impact"
                          item-text="label"
                          item-value="value"></v-autocomplete>
          <v-autocomplete label="État"
                          :items="etat"
                          v-model="asset.etat"
                          item-text="label"
                          item-value="value"></v-autocomplete>
          <v-autocomplete label="Type"
                          :items="type"
                          v-model="asset.type"
                          item-text="label"
                          item-value="value"></v-autocomplete>

          <v-textarea label="Description" v-model="asset.description"></v-textarea>
          <v-menu max-width="100%" v-model="menu_date_achat" :close-on-content-click="false">
            <template v-slot:activator="{on, attrs}">
              <v-text-field v-on="on" v-bind="attrs" label="Date d'achat"
                            :value="getFormattedDate(asset.date_achat)"></v-text-field>
            </template>
            <v-card>
              <v-date-picker v-model="asset.date_achat"></v-date-picker>
              <v-card-actions>
                <v-btn color="primary" text @click="menu_date_achat = false">Fermer</v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>
          <v-menu max-width="100%" v-model="menu_date_fin_garantie" :close-on-content-click="false">
            <template v-slot:activator="{on, attrs}">
              <v-text-field v-on="on" v-bind="attrs" label="Date de fin de garantie"
                            :value="getFormattedDate(asset.date_fin_garantie)"></v-text-field>
            </template>
            <v-card>
              <v-date-picker v-model="asset.date_fin_garantie"></v-date-picker>
              <v-card-actions>
                <v-btn color="primary" text @click="menu_date_fin_garantie = false">Fermer</v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>

          <v-autocomplete label="Contrats"
                          item-text="name"
                          item-value="id"
                          :items="contracts"
                          multiple v-model="asset.contrats"
                          chips>
          </v-autocomplete>
          <v-text-field type="number" label="Coût" v-model="asset.cout_achat"></v-text-field>
          <v-text-field type="number" label="Amortissement" v-model="asset.amortissement"></v-text-field>
        </v-form>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" v-if="operation === 'create'" text @click="createAsset">
          Créer l'équipement
        </v-btn>
        <v-btn color="primary" v-if="operation === 'update'" text @click="updateAsset">
          Mettre à jour
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>

import DateUtilMixin from "@/mixins/util/DateUtilMixin";
import ValidationRulesMixin from "@/mixins/util/ValidationRulesMixin";

const impact_select = [
  {"value": "low", "label": "Basse"},
  {"value": "medium", "label": "Moyen"},
  {"value": "high", "label": "Élevée"}
];

export default {
  name: "NewAssetSidebar",
  mixins: [DateUtilMixin, ValidationRulesMixin],
  props: {
    operation: String,
    assetToUpdate: Object
  },
  mounted() {
    if(this.$store.getters["users/users"].length === 0) {
      this.$store.dispatch("users/fetchUsers");
    }
    if(this.$store.getters["sites/allSites"].length === 0) {
      this.$store.dispatch("sites/fetchSites");
    }
    if(this.$store.getters["contracts/allContracts"].length === 0) {
      this.$store.dispatch("contracts/fetchContracts");
    }
  },
  data() {
    let emptyAsset = {
      "intervenant": null,
      "site": null,
      "name": "",
      "balise": "",
      "reference": "",
      "impact": null,
      "etat": null,
      "type": null,
      "numero_serie": "",
      "marque": "",
      "description": "",
      "cout_achat": null,
      "amortissement": null,
      "date_achat": null,
      "date_fin_garantie": null,
      "contrats": [],
    };
    let assetToUpdate = {...this.assetToUpdate};
    if(this.operation === "update" || this.operation === "reupdate") {
      assetToUpdate.category = assetToUpdate.category !== null ? this.assetToUpdate.category.id : null;
      assetToUpdate.intervenant = assetToUpdate.intervenant !== null ? this.assetToUpdate.intervenant.id : null;
      assetToUpdate.site = assetToUpdate.site !== null ? this.assetToUpdate.site.id : null;
    }
    return {
      asset: this.operation === "update" || this.operation === "reupdate" ? assetToUpdate : emptyAsset,
      impact: impact_select,
      etat: [
        {"value": "new", "label": "Nouveau"},
        {"value": "used", "label": "Utilisé"},
        {"value": "bad", "label": "Mauvais"},
        {"value": "hors_usage", "label": "Hors d'usage"}
      ],
      type: [
        {"value": "technique", "label": "Technique"},
        {"value": "bati", "label": "Bâti"}
      ],
      menu_date_achat: false,
      menu_date_fin_garantie: false,
    }
  },
  methods: {
    async createAsset() {
      if (this.$refs.assetForm.validate()) {
        let result = await this.$store.dispatch("assets/postAsset", this.asset);
        if (result === "success") {
          this.$store.dispatch("messages/announceSuccess", "Équipement créé.");
          this.$store.dispatch("drawer/closeDialog");
          this.resetFields();
          await this.$store.dispatch("contracts/fetchContracts");
        } else {
          await this.$store.dispatch("messages/announceError", "Une erreur est survenue.");
        }
      }
    },
    async updateAsset() {
      if (this.$refs.assetForm.validate()) {
        this.handleFields();
        let result = await this.$store.dispatch("assets/putAsset", this.asset);
        if (result === "success") {
          await this.$store.dispatch("messages/announceSuccess", "Équipement mis à jour.");
          await this.$store.dispatch("drawer/closeDialog");
          this.resetFields();
          await this.$store.dispatch("contracts/fetchContracts");
        } else {
          await this.$store.dispatch("messages/announceError", "Une erreur est survenue.");
        }
      }
    },
    handleFields: function () {
      if (Array.isArray(this.asset.contrats) && this.asset.contrats.length > 0) {
        this.asset.contrats = this.asset.contrats.map(function (contrat) {
          return typeof contrat === "object" ? contrat.id : contrat;
        });
      }
    },
    resetFields() {
      this.asset = {
        "intervenant": null,
        "site": null,
        "name": "",
        "balise": "",
        "reference": "",
        "impact": null,
        "etat": null,
        "type": null,
        "numero_serie": "",
        "marque": "",
        "description": "",
        "cout_achat": null,
        "amortissement": null,
        "date_achat": null,
        "date_fin_garantie": null,
        "contrats": [],
      };
    },
    uniqueReference(value) {
      if(this.operation === "update") return true;
      let assets = this.$store.getters["assets/allAssets"];
      let used_references = assets.map(asset => asset.reference);
      return (this.operation !== "update" && used_references.indexOf(value) === -1) || "Cette référence est déjà utilisé.";
    },
    uniqueSerialNumber(value) {
      if(this.operation === "update") return true;
      if(value === "" || value === null) return true;
      let assets = this.$store.getters["assets/allAssets"];
      let used_numero_serie = assets.map(asset => asset.numero_serie);
      return (this.operation !== "update" && used_numero_serie.indexOf(value) === -1) || "Ce numéro de série est déjà utilisé.";
    },
    uniqueName(value) {
      if(this.operation === "update") return true;
      if(value === "" || value === null) return true;
      let assets = this.$store.getters["assets/allAssets"];
      let used_names = assets.map(asset => asset.name);
      return (this.operation !== "update" && used_names.indexOf(value) === -1) || "Ce nom est déjà utilisé.";
    },
    uniqueBalise(value) {
      if(this.operation === "update") return true;
      let assets = this.$store.getters["assets/allAssets"];
      let used_code_barres = assets.map(asset => asset.balise);
      return used_code_barres.indexOf(value) === -1 || "Cette balise est déjà utilisé.";
    },
    limitCharacters(value) {
      return value.length <= 25 || "Le nom de l'équipement doit faire moins de 25 caractères.";
    }

  },
  computed: {
    domaines() {
      return this.$store.getters["categories/domaines"];
    },
    sites() {
      return this.$store.getters["sites/fullLocationSites"];
    },
    intervenants() {
      return this.$store.getters["users/users"];
    },
    contracts() {
      return [{id: null, name: ""}, ...this.$store.getters["contracts/allContracts"]]
    },
    sites_tree() {
      return this.$store.getters["sites/sitesTree"];
    }
  }
}
</script>

<style scoped>

</style>